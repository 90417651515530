import { msg, t } from "@lingui/core/macro";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router";
import { mapboxApi, patientApi } from "./ApiClient";
import { generatePatches, generateQueryString } from "./Helpers";
import { formatPostalCodeWithSpace } from "@/Utils/postalCodeUtils";
import type {
  IDeletedPatient,
  IExistingPatient,
  IPatientStatus,
  IRelative,
} from "@models/patients";
import type { listPatientSchema } from "@models/patients";
import {
  patientStatusSchema,
  patientStatusDictionary,
  existingPatientSchema,
  listExistingPatientSchema,
} from "@models/patients";
import type { IAddress, IAddressWithCoordinates } from "@models/addresses";
import { i18n } from "@lingui/core";
import { retrieveBrowserLocale } from "@/Utils/i18n";
import { z } from "zod";
import { fetchCareHub } from "./Routes";

type IListPatient = z.infer<typeof listPatientSchema>;

const existingPatientsSchema = z.array(listExistingPatientSchema);
export type IListExistingPatient = z.infer<typeof listExistingPatientSchema>;

export const patientKeys = {
  all: ["patients"] as const,
  lists: () => [...patientKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...patientKeys.lists(), { filters }] as const,
  listWithVariant: (filters: Record<string, unknown>) =>
    [...patientKeys.lists(), "withHasHomeVisit", filters] as const,
  detail: (id: string) => [...patientKeys.all, id, "details"] as const,
};

export const fetchPatients = async ({
  patientIds,
  statuses,
}: {
  patientIds?: string[];
  statuses?: IPatientStatus[];
}) => {
  const queryString = generateQueryString({ id: patientIds, status: statuses });
  const patientsResponse = await patientApi.get(`/patients${queryString}`);
  const parsedPatients = existingPatientsSchema.parse(patientsResponse.data);
  return parsedPatients;
};

export const createDeletedPatient = (patientId: string) => ({
  id: patientId,
  status: patientStatusSchema.Values.deleted,
});

export const resolvePatient = ({
  patientId,
  patients,
}: {
  patientId?: string | null;
  patients: ReadonlyArray<IListPatient>;
}) => {
  // AdminTask can miss patientId
  if (!patientId) {
    return null;
  }

  const patientInList = patients.find((patient) => patient.id === patientId);

  if (patientInList) {
    return patientInList;
  }

  // If no patient was found, assume it was deleted, and create a deleted patient stub
  return createDeletedPatient(patientId);
};

export async function fetchPatient(id: string) {
  const patientResponse = await patientApi.get(`/patients/${id}`);
  const parsedPatient = existingPatientSchema.parse(patientResponse.data);
  return parsedPatient;
}

export const usePatient = (patientId: string) => {
  return useQuery({
    queryKey: patientKeys.detail(patientId),
    queryFn: () => fetchPatient(patientId),
  });
};

export async function fetchCoordinates(
  parameters: Pick<IAddress, "addressLine1" | "postalCode">,
): Promise<{
  longitude: number;
  latitude: number;
}> {
  const careHub = await fetchCareHub();

  const { addressLine1: address_line1, postalCode } = parameters;
  const postcode = formatPostalCodeWithSpace(postalCode);

  // Ignored since Mapbox often thinks that the 'place' is the municipality, not the city.
  // const place = city;

  // Ignored since 'proximity' is more dynamic.
  // ~ 50 km north, west, east, south of careHub
  // const { minLon, minLat, maxLon, maxLat } = {
  //   minLon: careHub.longitude - 1,
  //   minLat: careHub.latitude - 0.5,
  //   maxLon: careHub.longitude + 1,
  //   maxLat: careHub.latitude + 0.5,
  // };
  // const bbox = `${minLon},${minLat},${maxLon},${maxLat}`;
  const country = "se";
  const language = retrieveBrowserLocale();
  const limit = 1;
  const proximity = `${careHub.longitude},${careHub.latitude}`;
  const types = "address";
  const queryString = generateQueryString({
    address_line1,
    postcode,
    // bbox,
    country,
    language,
    limit,
    // place,
    proximity,
    types,
  });

  // https://docs.mapbox.com/api/search/geocoding/#forward-geocoding-with-structured-input
  const forwardGeocodingURL = `/search/geocode/v6/forward${queryString}`;
  const response = await mapboxApi.get(forwardGeocodingURL);
  // Expected response as per: https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
  const coordinates: [number, number] =
    response.data.features[0].geometry.coordinates;
  return { longitude: coordinates[0], latitude: coordinates[1] };
}

export function useParentRoutesPatient() {
  return useOutletContext<IExistingPatient>();
}

export const updateAddress = async (
  patientId: string,
  address: IAddressWithCoordinates,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      address,
    }),
  );
};

export const updateDiagnosisCode = async (
  patientId: string,
  diagnosisCode: string,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      diagnosisCode,
    }),
  );
};

export const updatePhoneNumber = async (
  patientId: string,
  phoneNumber: string,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      phoneNumber,
    }),
  );
};

export const updateInformation = async (
  patientId: string,
  information: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      information,
    }),
  );
};

export const addRelative = async (patientId: string, relative: IRelative) => {
  await patientApi.post(`/patients/${patientId}/relatives`, {
    ...relative,
  });
};

export const removeRelative = async (patientId: string, relativeId: string) => {
  await patientApi.delete(`/patients/${patientId}/relatives/${relativeId}`);
};

export const updateHealthcareJourneyNumber = async (
  patientId: string,
  healthcareJourneyNumber: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({
      healthcareJourneyNumber,
    }),
  );
};

export const updateSafetyAlarmText = async (
  patientId: string,
  safetyAlarmText: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({ safetyAlarmText }),
  );
};

export const updatePets = async (
  patientId: string,
  pets: string | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({ pet: pets }),
  );
};

export const updateHomeCare = async (
  patientId: string,
  homeCare: boolean | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({ homeCare: Boolean(homeCare) }), // For checkboxes, this is always a boolean
  );
};

export const updateLifeCare = async (
  patientId: string,
  lifeCare: boolean | null | undefined,
) => {
  await patientApi.patch(
    `/patients/${patientId}`,
    generatePatches({ lifeCare: Boolean(lifeCare) }), // For checkboxes, this is always a boolean
  );
};

export const getPatientNameWithStatus = (
  patient:
    | Pick<IDeletedPatient, "status">
    | Pick<IExistingPatient, "name" | "status">,
): string => {
  if (patient.status === patientStatusSchema.Values.admitted) {
    return patient.name;
  }

  const localizedPatientStatus = i18n
    ._(patientStatusDictionary[patient.status].singular)
    .toLocaleLowerCase();

  if (patient.status === patientStatusSchema.Values.deleted) {
    return t`Okänd (${localizedPatientStatus})`;
  }

  return `${patient.name} (${i18n
    ._(patientStatusDictionary[patient.status].singular)
    .toLocaleLowerCase()})`;
};

/*****************************************************************************************************
 * CONDITIONAL PATIENT INFORMATION FIELDS
 *****************************************************************************************************/

export const conditionalPatientInformationFieldNames = [
  "DiagnosisCode",
  "AdditionalAdressInformation",
  "CloseContact",
  "HomeCare",
  "Pet",
  "PersWristband",
  "MedicalTransportNumber",
  "LifeCare",
  "GeneralInformation",
] as const;

export const conditionalPatientInformationFieldNamesDictionary = {
  DiagnosisCode: msg`Diagnoskod`,
  AdditionalAdressInformation: msg`Övrig adressinformation`,
  CloseContact: msg`Anhöriga`,
  HomeCare: msg`Hemtjänst`,
  Pet: msg`Husdjur`,
  PersWristband: msg`Sociala tjänster`,
  MedicalTransportNumber: msg`Sjukresenummer`,
  LifeCare: msg`LifeCare`,
  GeneralInformation: msg`Allmän information`,
};

const conditionalPatientInformationFieldNamesSchema = z.enum(
  conditionalPatientInformationFieldNames,
);

const availablePatientInformationFieldSchema = z.object({
  fieldName: conditionalPatientInformationFieldNamesSchema,
  required: z.boolean(),
});

export const availablePatientInformationFieldsSchema = z.array(
  availablePatientInformationFieldSchema,
);

export type IAvailablePatientInformationFields = z.infer<
  typeof availablePatientInformationFieldsSchema
>;

const fetchPatientInformationFields = async () => {
  const response = await patientApi.get("/patients/information-fields");
  const parsedData = availablePatientInformationFieldsSchema.parse(
    response.data,
  );
  return parsedData;
};

export const informationFieldConfigValueSchema = z.enum([
  "hidden",
  "optional",
  "required",
]);
export type IInformationFieldConfigValue = z.infer<
  typeof informationFieldConfigValueSchema
>;

const _informationFieldsConfigSchema = z.record(
  conditionalPatientInformationFieldNamesSchema,
  informationFieldConfigValueSchema,
);
export type IInformationFieldsConfig = z.infer<
  typeof _informationFieldsConfigSchema
>;

export const createInformationFieldsConfig = (
  availableFields: IAvailablePatientInformationFields,
) => {
  const config = {} as IInformationFieldsConfig;

  availableFields.forEach((item) => {
    config[item.fieldName] = item.required
      ? informationFieldConfigValueSchema.Values.required
      : informationFieldConfigValueSchema.Values.optional;
  });

  conditionalPatientInformationFieldNames.forEach((field) => {
    if (!config[field]) {
      config[field] = informationFieldConfigValueSchema.Values.hidden;
    }
  });

  return config;
};

const patientInformationFieldsKeys = {
  all: ["patientInformationFields"] as const,
};

export const usePatientInformationFields = () => {
  return useQuery({
    queryKey: [patientInformationFieldsKeys.all],
    queryFn: async () => {
      const availableFields = await fetchPatientInformationFields();
      const informationFields = createInformationFieldsConfig(availableFields);
      return informationFields;
    },
  });
};
