import { t } from "@lingui/core/macro";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Navigate } from "react-router";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { Link } from "@components/Link/Link";
import styles from "./IdsLogin.module.scss";
import { Trans } from "@lingui/react/macro";

const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.loginLayout}>{children}</div>;
};

export const Login = () => {
  const { activeNavigator, isLoading, isAuthenticated, error, user } =
    useAuth();

  if (isAuthenticated) {
    return <Navigate to={user?.state ? user.state : "/"} replace />;
  }

  if (activeNavigator || isLoading) {
    return (
      <LoginLayout>
        <Loading message={t`Loggar in`} />
      </LoginLayout>
    );
  }

  if (error) {
    Sentry.captureException(error);
    if (hasAuthParams()) {
      Sentry.captureException(
        "User ended up in an error state with preserved auth params",
      );
    }
    return (
      <LoginLayout>
        <ErrorMessage
          message={`${t`Kunde inte logga in.`} ${deducedError(error)}`}
        />
        {hasAuthParams() ? (
          <Link to="/">
            <Trans>Gå till startsidan</Trans>
          </Link>
        ) : null}
      </LoginLayout>
    );
  }

  // MED-4737: Sign out from pin-screen in Go
  // Before, this returned `null`, and then the user got stuck with a blank screen.
  // Now, we redirect to the root for Auth to take over.
  return <Navigate to="/" replace />;
};
