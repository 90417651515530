import { logisticsApi } from "@/api/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { employee } from "@models/shifts";
import { useUserProfile } from "./Users";

const fetchEmployee = async (employeeNumber: string) => {
  const employeeResponse = await logisticsApi.get(
    `/employees/${employeeNumber}`,
  );
  const parsedEmployee = employee.parse(employeeResponse.data);
  return parsedEmployee;
};

const employeeKeys = {
  all: ["employees"] as const,
  lists: () => [...employeeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...employeeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...employeeKeys.all, id, "details"] as const,
};

export const useCurrentEmployee = () => {
  const { employeeNumber } = useUserProfile();
  return useQuery({
    queryKey: employeeKeys.detail(employeeNumber),
    queryFn: () => fetchEmployee(employeeNumber),
    enabled: Boolean(employeeNumber),
  });
};
