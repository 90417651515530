import { useAuth } from "react-oidc-context";
import { z } from "zod";

const userProfileSchema = z.object({
  employee_number: z.string(),
  given_name: z.string(),
  unit: z.string(),
});

export const useUserProfile = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error(
      "No user found. This is usually because the hook is executed outside of an AuthProvider, or because the authentication is not completed yet.",
    );
  }

  const { employee_number, given_name, unit } = userProfileSchema.parse(
    user.profile,
    {
      errorMap: () => ({
        message:
          "Malformed or missing 'employee_number', 'given_name' or 'unit. Did you forget the 'employee_profile' or 'organization' scopes?",
      }),
    },
  );

  return { employeeNumber: employee_number, givenName: given_name, unit };
};
