import { t } from "@lingui/core/macro";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";
import styles from "./AmbulatingContainer.module.scss";
import clsx from "clsx";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import { applicationSchema, useMatrixClient } from "@chat/matrixClient";
import { useCallback, useEffect, useState } from "react";
import { ClientEvent } from "matrix-js-sdk";
import ChatContext from "@chat/ChatContext";
import { useUnreadNotifications } from "@chat/useUnreadNotifications";
import { usePushyRegistration } from "@/Utils/PushyUtils";
import { getEmployeeJwt } from "@/api/Chat";
import { useGoLogisticsSSEHandlers } from "@/Utils/sseHandlers";
import { LoadingOverlay } from "@components/Loading/Loading";
import Header from "@/pages/ambulating/Header";
import { useAppVisibilityBreadcrumbs } from "../useAppVisibilityBreadcrumbs";
import { userManager } from "@/Utils/EnvUtils";
import { z } from "zod";
import { useUserProfile } from "@/api/Users";

// https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission_static#browser_compatibility
// Not supported on iOS Safari < 16.4, iOS Safari not on Home Screen, or WebViews
const notificationsApiExistsOnClient = "Notification" in window;

const useNotificationPermissionGranted = () => {
  const [notificationPermissionGranted, setNotificationPermissionGranted] =
    useState(
      notificationsApiExistsOnClient && Notification.permission === "granted",
    );

  navigator.permissions
    .query({ name: "notifications" })
    .then((permissionStatus) => {
      permissionStatus.onchange = () => {
        setNotificationPermissionGranted(permissionStatus.state === "granted");
      };
    });
  return notificationPermissionGranted;
};

const NotificationPermitter = () => {
  const { employeeNumber } = useUserProfile();
  const pushyUserId = employeeNumber;
  const { registerDeviceWithPushyAndSyncPushyWithNotificationApi } =
    usePushyRegistration();

  const notificationPermissionGranted = useNotificationPermissionGranted();

  // Do a background "refresh", ensuring that the correct user is associated with the device
  useEffect(() => {
    if (notificationPermissionGranted) {
      registerDeviceWithPushyAndSyncPushyWithNotificationApi(pushyUserId);
    }
  }, [
    notificationPermissionGranted,
    pushyUserId,
    registerDeviceWithPushyAndSyncPushyWithNotificationApi,
  ]);

  if (notificationPermissionGranted) {
    return null;
  }

  return (
    <InlineAlert
      title={t`Tillåt aviseringar`}
      message={t`Din tillåtelse för aviseringar krävs för att kunna få notiser om nya meddelanden i chatten`}
      type="warning"
      actions={[
        {
          label: t`Tillåt`,
          onClick: () =>
            registerDeviceWithPushyAndSyncPushyWithNotificationApi(pushyUserId),
          isPrimary: true,
        },
      ]}
    />
  );
};

const useHasLoadedForSeconds = (seconds: number) => {
  const [
    hasLoadedLongerThanSuppliedTimeout,
    setHasLoadedLongerThanSuppliedTimeout,
  ] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasLoadedLongerThanSuppliedTimeout(true);
    }, seconds * 1000);
    return () => clearTimeout(timeout);
  }, [seconds]);

  return hasLoadedLongerThanSuppliedTimeout;
};

const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const getJwt = useCallback(
    () =>
      userManager.getUser().then((user) =>
        // TODO: Is this why we sometimes see that chat could not start?
        getEmployeeJwt(z.string().parse(user?.profile.employee_number)),
      ),
    [],
  );

  const matrixClient = useMatrixClient(
    applicationSchema.Values.Go,
    getJwt,
    true,
  );
  const loadedForALongTime = useHasLoadedForSeconds(3);

  // Use debug chat
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    if (import.meta.env.VITE_DEBUG_CHAT) {
      matrixClient.on(ClientEvent.Event, console.log);

      return () => {
        matrixClient.off(ClientEvent.Event, console.log);
      };
    }
  }, [matrixClient]);

  const unreadNotifications = useUnreadNotifications(matrixClient);
  const chatIsFullyLoaded = unreadNotifications !== undefined;

  return (
    <ChatContext.Provider
      value={{ matrixClient, unreadNotifications, chatEnabled: true }}
    >
      <LoadingOverlay show={!chatIsFullyLoaded && !loadedForALongTime}>
        {children}
      </LoadingOverlay>
    </ChatContext.Provider>
  );
};

const AmbulatingContainer = () => {
  useGoLogisticsSSEHandlers();
  useAppVisibilityBreadcrumbs();

  return (
    <ChatProvider>
      <div
        className={clsx([
          styles.ambulatingContainer,
          styles.componentVariableOverrides,
        ])}
      >
        <Header />
        <main className={styles.main}>
          <section className={styles.banners}>
            <NotificationPermitter />
          </section>
          <Helmet>
            <body className={styles.body} />
          </Helmet>
          <Outlet />
        </main>
      </div>
    </ChatProvider>
  );
};

export default AmbulatingContainer;
