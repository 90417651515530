import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { isUnauthenticatedError } from "./Utils/ErrorUtils";
import { userManager, ENVIRONMENT } from "./Utils/EnvUtils";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router";
import { Login } from "./pages/login/IdsLogin";
import Routes from "./pages/ambulating/Routes/Routes";
import VisitDetails from "./pages/ambulating/Routes/$routeId/visits/$visitId";
import AmbulatingContainer from "./pages/ambulating/AmbulatingContainer";
import { Patient as PatientGo } from "./pages/ambulating/patients/$patientId/Patient";
import { Information as InformationGo } from "./pages/ambulating/patients/$patientId/Information";
import {
  EditAddressWrapper,
  EditDiagnosisCodeWrapper,
  EditHealthcareJourneyNumberWrapper,
  EditHomeCareWrapper,
  EditLifeCareWrapper,
  EditOtherInformationWrapper,
  EditPetsWrapper,
  EditPhoneNumberWrapper,
  EditRelativesWrapper,
  EditSafetyAlarmWrapper,
} from "./pages/ambulating/patients/$patientId/EditInformationWrappers";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-oidc-context";
import { TabsView as GoTabsView } from "./pages/ambulating";
import { OtherActivities } from "./pages/ambulating/OtherActivities/OtherActivities";
import Chat from "./pages/ambulating/chat/Chat";
import Pushy from "pushy-sdk-web";
import * as Sentry from "@sentry/react";
import { I18nApp } from "./I18nApp";
import { RequireGoAuth } from "./components/RequireAuth/RequireGoAuth";
import { PatientAuthentication } from "./pages/ambulating/patients/$patientId/PatientAuthentication";
import { Trans } from "@lingui/react/macro";

Sentry.init({
  debug: import.meta.env.VITE_SENTRY,
  enabled: import.meta.env.VITE_SENTRY || import.meta.env.PROD,
  dsn: "https://2e11fdb0dc43e11933c301af40f8d680@o4506739699220480.ingest.sentry.io/4508376722571264",
  environment: ENVIRONMENT,
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        // RegExp for `fetchVisit`, `fetchAmbulatingRoutes`, and `fetchRoute` used for debugging MED-2522, MED-2257
        /^.*logistics.*\/routes\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\/visits\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}$/,
        /^.*logistics.*\/ambulating\/routes.*$/,
        /^.*logistics.*\/routes\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}$/,
      ],
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
  const { worker } = await import("./mocks/browser");
  worker.start({
    onUnhandledRequest(req) {
      if (req?.url?.includes("medoma"))
        console.error(
          "Found an unhandled %s request to %s",
          req.method,
          req.url,
        );
    },
  });
  worker.listHandlers();
}

// Force stop background token refresh when Go is backgrounded
window.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "hidden") {
    userManager.stopSilentRenew();
  } else {
    // Restart background token refresh when Go is foregrounded
    userManager.startSilentRenew();
    // Check for expired or missing token
    userManager
      .getUser()
      .then((user) => {
        if (!user || user.expired) {
          // Pre-emptively trigger an MFA check rather than waiting for a failed request
          userManager.signinRedirect({
            state: window.location.pathname,
            acr_values: "mfa",
          });
        }
      })
      .catch(() => {
        userManager.signinRedirect({
          state: window.location.pathname,
          acr_values: "mfa",
        });
      });
  }
});

// Navigate on push notification click
Pushy.setNotificationListener((data) => {
  if (!JSON.stringify(data).includes("navigate")) return;
  if (!JSON.stringify(data).includes("_pushy")) return;
  if (data?.action === "navigate" && data.url) {
    router.navigate(new URL(data.url).pathname);
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        return count < 3 && !isUnauthenticatedError(error);
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireGoAuth>
        <AmbulatingContainer />
      </RequireGoAuth>
    ),
    errorElement: (
      <div>
        <Trans>
          <h1>Något gick fel</h1>
          <p>Ladda om sidan</p>
        </Trans>
      </div>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="routes" replace />,
      },
      {
        element: <GoTabsView />,
        children: [
          {
            index: true,
            element: <Navigate to="routes" replace />,
          },
          {
            path: "routes",
            children: [
              {
                index: true,
                element: <Routes />,
              },
              {
                path: ":routeId/visits/:visitId",
                element: <VisitDetails />,
              },
            ],
          },
          {
            path: "other-activities",
            element: <OtherActivities />,
          },
        ],
      },
      {
        path: "patients",
        children: [
          {
            path: ":patientId",
            element: <PatientGo />,
            children: [
              {
                index: true,
                element: <InformationGo />,
              },
              {
                path: "edit-diagnosis-code",
                element: <EditDiagnosisCodeWrapper />,
              },
              {
                path: "edit-address",
                element: <EditAddressWrapper />,
              },
              {
                path: "edit-phone-number",
                element: <EditPhoneNumberWrapper />,
              },
              {
                path: "edit-relatives",
                element: <EditRelativesWrapper />,
              },
              {
                path: "edit-other-information",
                element: <EditOtherInformationWrapper />,
              },
              {
                path: "edit-safety-alarm",
                element: <EditSafetyAlarmWrapper />,
              },
              {
                path: "edit-pets",
                element: <EditPetsWrapper />,
              },
              {
                path: "edit-healthcare-journey-number",
                element: <EditHealthcareJourneyNumberWrapper />,
              },
              {
                path: "edit-homecare",
                element: <EditHomeCareWrapper />,
              },
              {
                path: "edit-lifecare",
                element: <EditLifeCareWrapper />,
              },
              {
                path: "authentication",
                element: <PatientAuthentication />,
              },
            ],
          },
        ],
      },
      {
        path: "chat",
        element: <Chat />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
  {
    path: "status",
    element: <p>OK</p>,
  },
]);

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <AuthProvider userManager={userManager}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <I18nApp>
            <RouterProvider router={router} />
          </I18nApp>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
