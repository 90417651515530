import { CallChooser } from "@/components/CallChooser/CallChooser";
import { Link } from "@components/Link/Link";
import styles from "@/pages/ambulating/patients/$patientId/CallPatientOrRelatives.module.scss";
import PhoneIcon from "@components/icons/PhoneIcon";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";

export const CallPatientOrRelatives = ({
  patient,
  relatives,
}: {
  patient: { name: string; phoneNumber?: string | null | undefined };
  relatives: ReadonlyArray<{ name: string; phoneNumber: string }>;
}) => {
  const { _ } = useLingui();
  const patientName = patient.name;
  return patient.phoneNumber && relatives.length >= 1 ? (
    <CallChooser
      contacts={[
        {
          name: patient.name,
          phoneNumber: patient.phoneNumber,
        },
        ...relatives,
      ]}
    />
  ) : patient.phoneNumber ? (
    <Link
      aria-label={_(msg`Ring ${patientName}`)}
      className={styles.phoneLink}
      type="phone"
      to={patient.phoneNumber}
    >
      <PhoneIcon />
    </Link>
  ) : (
    // Workaround for scenario where patient has no phone number.
    // This only happens for Prospect/Declined as of 2023-11-03.
    // Therefore, it is very unlikely to happen and we can live with this workaround.
    <div>
      <PhoneIcon /> <Trans>saknas</Trans>
    </div>
  );
};
