import axios, {
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import {
  AUTH_API_URL,
  getLogisticsApiUrl,
  getNotificationApiUrl,
  getPatientApiUrl,
  MAPBOX_API_URL,
  MAPBOX_PUBLIC_ACCESS_TOKEN,
  userManager,
} from "../Utils/EnvUtils";
import { User } from "oidc-client-ts";
import {
  isUnauthenticatedError,
  isUnauthorizedError,
} from "@/Utils/ErrorUtils";

const authApi = axios.create({
  baseURL: AUTH_API_URL,
});

const PLACEHOLDER_LOGISTICS = "placeholder-logistics";
const PLACEHOLDER_PATIENT = "placeholder-patient";
const PLACEHOLDER_NOTIFICATION = "placeholder-notification";

const logisticsApi = axios.create({
  baseURL: PLACEHOLDER_LOGISTICS,
});
const patientApi = axios.create({
  baseURL: PLACEHOLDER_PATIENT,
});
const notificationApi = axios.create({
  baseURL: PLACEHOLDER_NOTIFICATION,
});

// On initial load of the application, check for existing user
userManager.getUser().then((user) => {
  if (!user) return;
  const unit = user?.profile.unit as string;
  logisticsApi.defaults.baseURL = getLogisticsApiUrl(unit);
  patientApi.defaults.baseURL = getPatientApiUrl(unit);
  notificationApi.defaults.baseURL = getNotificationApiUrl(unit);
});

// When the user is loaded, and the unit becomes available, update the baseURL accordingly
userManager.events.addUserLoaded(async () => {
  const unit = await userManager
    .getUser()
    .then((user) => user?.profile.unit as string);
  logisticsApi.defaults.baseURL = getLogisticsApiUrl(unit);
  patientApi.defaults.baseURL = getPatientApiUrl(unit);
  notificationApi.defaults.baseURL = getNotificationApiUrl(unit);
});

const signinRedirect = async () => {
  userManager.signinRedirect({
    state: window.location.pathname,
    acr_values: "mfa",
  });
};

const getAuthenticatedUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${AUTH_API_URL}:medoma-go`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  if (user?.expired) {
    signinRedirect();
  }

  return user;
};

const injectBearerToken = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (config && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const requestHandler = (config: InternalAxiosRequestConfig) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") {
    // Adjust baseURL in test scenario.
    if (config.baseURL === PLACEHOLDER_LOGISTICS) {
      config.baseURL = getLogisticsApiUrl("medoma");
    }
    if (config.baseURL === PLACEHOLDER_PATIENT) {
      config.baseURL = getPatientApiUrl("medoma");
    }
    if (config.baseURL === PLACEHOLDER_NOTIFICATION) {
      config.baseURL = getNotificationApiUrl("medoma");
    }
    return config;
  }
  const unexpiredUser = getAuthenticatedUser();
  return injectBearerToken(config, unexpiredUser?.access_token);
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: unknown) => {
  // This typically happens if:
  // The token is expired, or
  // The token was forcibly invalidated, e.g. by closing the session from an admin panel
  if (isUnauthenticatedError(error)) {
    signinRedirect();
  }

  // This typically happens if:
  // The token is still valid, but the MFA timestamp is too old
  // This should only happen if accepted MFA age is shorter than token lifetime
  if (isUnauthorizedError(error)) {
    signinRedirect();
  }

  return Promise.reject(error);
};

authApi.interceptors.request.use(requestHandler);
logisticsApi.interceptors.request.use(requestHandler);
logisticsApi.interceptors.response.use(responseHandler, errorHandler);
patientApi.interceptors.request.use(requestHandler);
patientApi.interceptors.response.use(responseHandler, errorHandler);
notificationApi.interceptors.request.use(requestHandler);
notificationApi.interceptors.response.use(responseHandler, errorHandler);

const mapboxApi = axios.create({
  baseURL: MAPBOX_API_URL,
});

mapboxApi.interceptors.request.use((config) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const token = MAPBOX_PUBLIC_ACCESS_TOKEN;
  if (config && config.url) {
    config.url = config.url.concat(`&access_token=${token}`);
  }
  return config;
});

export { authApi, mapboxApi, patientApi, logisticsApi, notificationApi };
