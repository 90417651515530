/* eslint-disable prefer-const */
// We have a pattern to use `let` in these files. Don't mess with it.

import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? () => `https://localhost:7240`
    : (unit: string) => `https://logistics-${unit}-dev.medoma.com`;
let mapboxApiUrl = "https://api.mapbox.com";
let mapboxPublicAccessToken =
  "pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ";
let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? () => `https://localhost:7023`
    : (unit: string) => `https://pas-${unit}-dev.medoma.com`;
let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? `https://localhost:5001`
    : `https://auth-dev.medoma.com`;
let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_NOTIFICATION_BACKEND
    ? () => `https://localhost:7240/notification`
    : (unit: string) => `https://logistics-${unit}-dev.medoma.com/notification`;
let pushyAppId = "675ad8c47a327a8229eef939";
let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "go.medoma.com") {
  logisticsApiUrl = (unit: string) => `https://logistics-${unit}.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}.medoma.com`;
  authApiUrl = `https://auth.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}.medoma.com/notification`;
  pushyAppId = "675ada15377b179337a40445";
  environment = "prod";
} else if (hostname === "go.test.medoma.com") {
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-test.medoma.com`;
  authApiUrl = `https://auth-test.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com/notification`;
  pushyAppId = "675aac7c377b179337a4043f";
  environment = "test";
} else if (hostname === "go.demo.medoma.com") {
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-demo.medoma.com`;
  authApiUrl = `https://auth-demo.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com/notification`;
  pushyAppId = "675ad9e6377b179337a40443";
  environment = "demo";
}

const oidcConfig: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-go",
  redirect_uri: `${window.location.origin}/login`,
  scope:
    "patient.api logistic.api user_type mfa create.otp revoke_session IdentityServerApi set_temporary_pin employee_profile issued_for_app openid organization",
};
export const userManager = new UserManager(oidcConfig);

export const {
  getLogisticsApiUrl,
  getPatientApiUrl,
  getNotificationApiUrl,
  MAPBOX_API_URL,
  MAPBOX_PUBLIC_ACCESS_TOKEN,
  AUTH_API_URL,
  PUSHY_APP_ID,
  ENVIRONMENT,
  CHAT_ENVIRONMENT,
} = {
  getLogisticsApiUrl: (unit: string) => logisticsApiUrl(unit),
  getPatientApiUrl: (unit: string) => patientApiUrl(unit),
  getNotificationApiUrl: (unit: string) => notificationApiUrl(unit),
  MAPBOX_API_URL: mapboxApiUrl,
  MAPBOX_PUBLIC_ACCESS_TOKEN: mapboxPublicAccessToken,
  AUTH_API_URL: authApiUrl,
  PUSHY_APP_ID: pushyAppId,
  ENVIRONMENT: environment,
  CHAT_ENVIRONMENT: environment === "local" ? "dev" : environment,
};
