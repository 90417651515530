import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link as BaseLink, useNavigate } from "react-router";
import { routeKeys, startTravellingToVisit } from "@/api/Routes";
import Address from "@/components/Address/Address";
import { FilledButton } from "@components/Button/Button";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { StatusBar } from "@/components/StatusBar/StatusBar";
import { Time } from "@/components/Time/Time";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import styles from "./VisitCard.module.scss";
import { Heading } from "@components/Heading/Heading";
import type { IVisit } from "@models/visits";
import { calculateVisitTimes } from "@models/visits";
import { getPatientNameWithStatus } from "@/api/Patients";
import HiddenIcon from "@components/icons/HiddenIcon";
import * as Sentry from "@sentry/react";
import { employeeName } from "@models/shifts";
import { timeOfDayDictionary } from "@models/activities";
import { Text, TextWithLineBreaks } from "@components/Text/Text";
import { i18n } from "@lingui/core";
import {
  patientStatusSchema,
  type IDeletedPatient,
  type IExistingPatient,
} from "@models/patients";
import DocumentWithTextIcon from "@components/icons/DocumentWithTextIcon";

interface IVisitCard {
  isUpcomingVisit: boolean;
  visit: IVisit;
  routeId: string;
}

const PatientName = ({ name }: { name: string }) => {
  return (
    <Heading level="h3" className={styles.name}>
      {name}
    </Heading>
  );
};

const getTimespan = (visit: IVisit) => {
  const visitTimes = calculateVisitTimes(visit);
  if (visitTimes.isAnyTimeOfDay) return i18n._(timeOfDayDictionary.Any.short);
  if (visitTimes.start === undefined) return "";
  if (visitTimes.end === undefined) return "";
  return formattedTimeSpan(visitTimes.start, visitTimes.end);
};

const PatientAddress = ({
  patient,
}: {
  patient: IDeletedPatient | Pick<IExistingPatient, "address" | "status">;
}) => {
  if (patient.status === patientStatusSchema.Values.deleted) {
    return null;
  }
  return (
    <>
      <Address address={patient.address} />
      {patient.address.additionalInformation ? (
        <ul className={styles.addressInfoBox}>
          {patient.address.additionalInformation
            ?.split("\n")
            .map((item, index) => <li key={item + index}>{item}</li>)}
        </ul>
      ) : null}
    </>
  );
};

const VisitCard = ({ visit, isUpcomingVisit, routeId }: IVisitCard) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: startTravellingToVisitMutation, isPending } = useMutation({
    mutationFn: ({ routeId, visitId }: { routeId: string; visitId: string }) =>
      startTravellingToVisit(routeId, visitId),
    onSuccess: async () => {
      // Navigate before refetch for snappy UI
      navigate(`${routeId}/visits/${visit.id}`);
      await queryClient.refetchQueries({ queryKey: routeKeys.all });
    },
    onError: async (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        (error.response.data.code ===
          knownErrorCodeSchema.Values.AlreadyTravellingToVisit ||
          error.response.data.code ===
            knownErrorCodeSchema.Values.AlreadyTravelledToVisit)
      ) {
        Sentry.captureException(
          "Failed to start travelling to visit from VisitCard. Visit was already being travelled to. Refetching and re-navigating.",
        );
        // Navigate before refetch for snappy UI
        navigate(`${routeId}/visits/${visit.id}`);
        await queryClient.refetchQueries({ queryKey: routeKeys.all });
      } else {
        displayErrorMessageAlert(
          `${t`Gick inte att påbörja resan till besöket.`} ${deducedError(error)}`,
        );
      }
    },
  });

  const isActiveVisit =
    visit.status === "travellingTo" || visit.status === "ongoing";

  const VisitStartedAt = ({ startedAt }: { startedAt: Date }) => {
    const visitStartedAtTime = i18n.date(startedAt, { timeStyle: "short" });
    return (
      <Text element="div" color="faded">
        <Trans>Startat {visitStartedAtTime}</Trans>
      </Text>
    );
  };

  const VisitFinishedAt = ({ finishedAt }: { finishedAt: Date }) => {
    const visitFinishedAtTime = i18n.date(finishedAt, { timeStyle: "short" });
    return (
      <div className={styles.finishedAt}>
        <Text element="div" color="faded">
          <Trans>Utfört {visitFinishedAtTime}</Trans>
        </Text>
        <CheckmarkIcon />
      </div>
    );
  };

  return (
    <li className={styles.visitCard}>
      <BaseLink
        to={`${routeId}/visits/${visit.id}`}
        className={styles.cardLink}
      >
        {visit.status === "finished" ? (
          <div className={styles.finishedVisit}>
            <PatientName name={getPatientNameWithStatus(visit.patient)} />
            {visit.finishedAt ? (
              <VisitFinishedAt finishedAt={visit.finishedAt} />
            ) : (
              <CheckmarkIcon />
            )}
          </div>
        ) : (
          <>
            <header className={styles.header}>
              <div className={styles.topRow}>
                <PatientName name={getPatientNameWithStatus(visit.patient)} />
                {isActiveVisit && visit.startedAt ? (
                  <VisitStartedAt startedAt={visit.startedAt} />
                ) : (
                  <Time>{getTimespan(visit)}</Time>
                )}
              </div>
              <PatientAddress patient={visit.patient} />
            </header>
            <ul className={styles.occurrences}>
              {visit.occurrences.map((occurrence) => {
                return (
                  <li key={occurrence.id}>
                    <div className={styles.activityTitle}>
                      <Text element="div">{occurrence.title}</Text>
                      {occurrence.hidden ? <HiddenIcon /> : null}
                    </div>
                    {occurrence.description ? (
                      <div className={styles.description}>
                        <DocumentWithTextIcon />
                        <TextWithLineBreaks size="small" color="faded">
                          {occurrence.description}
                        </TextWithLineBreaks>
                      </div>
                    ) : null}
                  </li>
                );
              })}
            </ul>
            <ul className={styles.assignees}>
              {visit.assignees.map((shift) => {
                return (
                  <li key={shift.id}>
                    <CompetenceChip
                      competence={shift.competence}
                      state="neutral"
                      content={
                        shift.employee ? employeeName(shift.employee) : ""
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {isActiveVisit ? (
          <div className={styles.statusBarWrapper}>
            <StatusBar>
              {visit.status === "travellingTo" ? (
                <Trans>Pågående resa</Trans>
              ) : (
                <Trans>Pågående besök</Trans>
              )}
            </StatusBar>
          </div>
        ) : (
          <></>
        )}
      </BaseLink>
      {isUpcomingVisit ? (
        <footer className={styles.footer}>
          <FilledButton
            onClick={() =>
              startTravellingToVisitMutation({
                routeId: routeId,
                visitId: visit.id,
              })
            }
            disabled={isPending}
            width="fill-container"
          >
            <Trans>Starta resa</Trans>
          </FilledButton>
        </footer>
      ) : (
        <></>
      )}
    </li>
  );
};

export default VisitCard;
