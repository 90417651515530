import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { z } from "zod";
import {
  informationFieldConfigValueSchema,
  usePatient,
  usePatientInformationFields,
} from "@/api/Patients";
import { useParams } from "react-router";
import styles from "./Information.module.scss";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { formatPostalCodeWithSpace } from "@/Utils/postalCodeUtils";
import { NavCard } from "@/components/NavCard/NavCard";
import { Heading } from "@components/Heading/Heading";
import * as Sentry from "@sentry/react";
import { TextWithLineBreaks } from "@components/Text/Text";

export const Information = () => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient, isPending, isError, error } = usePatient(patientId);
  const {
    data: conditionalInformationFields,
    isPending: isPendingConditionalInformationFields,
    isError: isErrorConditionalInformationFields,
    error: errorConditionalInformationFields,
  } = usePatientInformationFields();

  if (isPending || isPendingConditionalInformationFields) {
    return <Loading message={t`Laddar patientinformation`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  if (isErrorConditionalInformationFields) {
    Sentry.captureException(errorConditionalInformationFields);
    return (
      <ErrorMessage
        message={deducedError(errorConditionalInformationFields)}
        padding={24}
      />
    );
  }

  const {
    name: patientName,
    address,
    phoneNumber,
    relatives,
    information,
    safetyAlarmText,
    pet,
    homeCare,
    lifeCare,
    healthcareJourneyNumber,
    diagnosisCode,
  } = patient;

  return (
    <section className={styles.infoSection}>
      {/* PATIENT AUTHENTICATION */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Inloggning</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="authentication"
      ></NavCard>

      {/* DIAGNOSIS */}
      {conditionalInformationFields.DiagnosisCode !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Diagnoskod</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-diagnosis-code"
        >
          <p>{diagnosisCode}</p>
        </NavCard>
      ) : null}

      {/* ADDRESS */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Adress</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-address"
      >
        <p>
          {address.addressLine1}
          <br />
          {formatPostalCodeWithSpace(address.postalCode)} {address.city}
        </p>
        <TextWithLineBreaks>{address.additionalInformation}</TextWithLineBreaks>
      </NavCard>

      {/* PHONE */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Telefonnummer</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-phone-number"
      >
        <p>{phoneNumber}</p>
      </NavCard>

      {/* OTHER INFORMATION */}
      {conditionalInformationFields.GeneralInformation !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Allmän information</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-other-information"
        >
          {information ? (
            <TextWithLineBreaks>{information}</TextWithLineBreaks>
          ) : (
            <p>-</p>
          )}
        </NavCard>
      ) : null}

      {/* RELATIVES */}
      {conditionalInformationFields.CloseContact !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Anhöriga</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-relatives"
        >
          {relatives.map(
            ({ id, name, relation, isLivingTogether, phoneNumber }) => {
              const optionalLivesTogetherWithSegment = isLivingTogether
                ? t`bor med ${patientName} och`
                : "";
              return (
                <p key={id}>
                  <Trans>
                    {name} ({relation}) {optionalLivesTogetherWithSegment} nås
                    på {phoneNumber}
                  </Trans>
                </p>
              );
            },
          )}
        </NavCard>
      ) : null}

      {/* PETS */}
      {conditionalInformationFields.Pet !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Husdjur</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-pets"
        >
          <p>{pet ?? "-"}</p>
        </NavCard>
      ) : null}

      {/* SAFETY ALARM */}
      {conditionalInformationFields.PersWristband !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Sociala tjänster</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-safety-alarm"
        >
          <TextWithLineBreaks>{safetyAlarmText ?? "-"}</TextWithLineBreaks>
        </NavCard>
      ) : null}

      {/* HEALTHCARE JOURNEY NUMBER */}
      {conditionalInformationFields.MedicalTransportNumber !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Sjukresenummer</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-healthcare-journey-number"
        >
          <p>{healthcareJourneyNumber ?? "-"}</p>
        </NavCard>
      ) : null}

      {/* HOMECARE */}
      {conditionalInformationFields.HomeCare !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>Hemtjänst</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-homecare"
        >
          <p>{homeCare ? <Trans>Ja</Trans> : <Trans>Nej</Trans>}</p>
        </NavCard>
      ) : null}

      {/* LIFECARE */}
      {conditionalInformationFields.LifeCare !==
      informationFieldConfigValueSchema.Values.hidden ? (
        <NavCard
          title={
            <Heading level="h2">
              <Trans>LifeCare</Trans>
            </Heading>
          }
          titleWeight="bold"
          to="edit-lifecare"
        >
          <p>{lifeCare ? <Trans>Ja</Trans> : <Trans>Nej</Trans>}</p>
        </NavCard>
      ) : null}
    </section>
  );
};
