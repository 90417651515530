import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Chat.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import Chat from "@components/Chat/Chat";
import { Loading } from "@components/Loading/Loading";
import ChatContext from "@chat/ChatContext";
import { formatTimestamp, makeMessages } from "@chat/useMatrixEvents";
import { useSendReadReceipt } from "@chat/useSendReadReceipt";
import { useMatrixRoom } from "@chat/useMatrixRoom";
import { sendTextMessage } from "@chat/matrixClient";
import { useChatScroll } from "@chat/useChatScroll";
import { SyncState } from "matrix-js-sdk";
import { useMatrixConnectionStatus } from "@chat/useMatrixConnectionStatus";
import { useMatrixTimelineEvents } from "@chat/useMatrixEvents";
import {
  lastEventReadByUserOtherThanUserId,
  useLastReadEventPerUser,
} from "@chat/useLastReadEvent";
import { getHistory } from "@chat/getHistory";
import { Heading } from "@components/Heading/Heading";
import { useScheduledCenterShifts } from "@/api/Shifts";
import { Text } from "@components/Text/Text";
import { employeeName, medicalTitle } from "@models/shifts";
import { useOtherUserIsTyping } from "@chat/useOtherUserIsTyping";
import { useSendTypingNotification } from "@chat/useSendTypingNotification";
import { startOfDay } from "date-fns";
import { getEmployeeRoomAlias } from "@chat/roomAlias";
import { useUserProfile } from "@/api/Users";
import { CHAT_ENVIRONMENT } from "@/Utils/EnvUtils";

const formatter = new Intl.ListFormat(navigator.language, {
  style: "long",
  type: "conjunction",
});

const PeopleInCenterNow = () => {
  const now = new Date();
  const today = startOfDay(now);

  const { data: centerShifts } = useScheduledCenterShifts(today, today);

  if (!centerShifts) {
    return null;
  }

  const centerShiftsNow = centerShifts.filter(
    (shift) => shift.startDateTime <= now && shift.endDateTime >= now,
  );

  return (
    <Text element="p">
      {formatter.format(
        centerShiftsNow.map((shift) =>
          shift.employee ? employeeName(shift.employee) : "",
        ),
      )}
    </Text>
  );
};

const ChatView = () => {
  const [pendingMessage, setPendingMessage] = useState("");
  const { employeeNumber, unit } = useUserProfile();
  const { matrixClient } = useContext(ChatContext);
  const centerMatrixUserId = `@patient-api-${unit}-${CHAT_ENVIRONMENT}-matrix-center-user:${matrixClient?.getDomain()}`;
  const { otherUserIsTyping: centerIsTyping } = useOtherUserIsTyping({
    matrixClient,
    otherUserMatrixId: centerMatrixUserId,
  });
  const chatConnection = useMatrixConnectionStatus(matrixClient);
  const roomAlias = getEmployeeRoomAlias({
    employeeId: employeeNumber,
    env: CHAT_ENVIRONMENT,
    matrixClient,
    unit,
  });
  const { roomId, getRoom } = useMatrixRoom(matrixClient, roomAlias);
  const { sendNoLongerTypingNotification, updateTypingState } =
    useSendTypingNotification({
      matrixClient,
      roomId,
    });

  // Send no longer typing notification when leaving chat
  useEffect(() => {
    return () => {
      sendNoLongerTypingNotification();
    };
  }, [sendNoLongerTypingNotification]);
  const userId = matrixClient?.getUserId() ?? undefined;

  const timelineEvents = useMatrixTimelineEvents(matrixClient, roomId);

  const lastReadEventBySomeNonGoEmployeeUser =
    lastEventReadByUserOtherThanUserId(
      useLastReadEventPerUser({ getRoom }),
      userId,
    );

  const shouldRenderInfoPanel =
    centerIsTyping || chatConnection === SyncState.Error;

  const messages = userId ? makeMessages(timelineEvents, userId) : [];

  const latestMessage = messages[messages.length - 1]?.id ?? null;

  useSendReadReceipt(matrixClient, timelineEvents, userId);

  const messagePanelRef = useRef<HTMLDivElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const { scrollMessagePanelToBottom } = useChatScroll({
    messagePanelRef,
    inputTextAreaRef,
    messages,
  });

  const sendMessage = () => {
    if (!matrixClient || !roomId) return;
    sendTextMessage(matrixClient, roomId, pendingMessage, () =>
      setPendingMessage(""),
    );
    sendNoLongerTypingNotification();
  };

  const room = getRoom();
  if (!matrixClient || !room) {
    return <Loading message={t`Laddar chatt med Center`} padding={24} />;
  }

  // Clear app badge when entering chat (is set in service-worker.js)
  // Not available in Safari browser unless the website is added to home screen
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clearAppBadge
  if (navigator.clearAppBadge) {
    navigator.clearAppBadge();
  }

  return (
    <article className={styles.chatContainer}>
      <div className={styles.chatWrapper}>
        <Chat>
          <Chat.HeaderPanel>
            <div className={styles.chatHeader}>
              <Heading level="h1" size="h3">
                <Trans>Chatt med Center</Trans>
              </Heading>
              <PeopleInCenterNow />
            </div>
          </Chat.HeaderPanel>
          <Chat.MessagePanel
            getHistory={() => getHistory(matrixClient, room)}
            ref={messagePanelRef}
          >
            {messages.map((message) =>
              message.sender.isMe ? (
                <Chat.MessageGroup
                  key={message.id}
                  sender="me"
                  status={message.status}
                  timestamp={formatTimestamp(message.epochTimestamp)}
                  readIndicator={
                    message.id === lastReadEventBySomeNonGoEmployeeUser?.eventId
                      ? lastReadEventBySomeNonGoEmployeeUser.timestamp
                      : undefined
                  }
                  isLatestMessage={latestMessage === message.id}
                >
                  <Chat.Message type="sent-by-me" message={message.text} />
                </Chat.MessageGroup>
              ) : (
                <Chat.MessageGroup
                  key={message.id}
                  sender="other-person"
                  senderName={message.sender.name ?? t`Center`}
                  senderTitle={
                    medicalTitle(message.sender.title, {
                      format: "short",
                    }) ?? null
                  }
                  senderPhotoURL={message.sender.imageURL ?? null}
                  status={message.status}
                  timestamp={formatTimestamp(message.epochTimestamp)}
                >
                  <Chat.Message type="received" message={message.text} />
                </Chat.MessageGroup>
              ),
            )}
          </Chat.MessagePanel>
          {shouldRenderInfoPanel ? (
            <Chat.InfoPanel
              type={chatConnection === SyncState.Error ? "error" : "info"}
            >
              {chatConnection === SyncState.Error ? (
                t`Chatten fungerar inte just nu`
              ) : (
                <Chat.IsTyping text={t`Center skriver`} />
              )}
            </Chat.InfoPanel>
          ) : (
            <></>
          )}
          <Chat.InputPanel>
            <Chat.InputTextArea
              inputTextAreaRef={inputTextAreaRef}
              value={pendingMessage}
              onChange={(event) => {
                setPendingMessage(event.currentTarget.value);
                updateTypingState({ event, pendingMessage });
              }}
              onFocus={scrollMessagePanelToBottom}
            />
            <Chat.SendMessageButton
              aria-label={t`Skicka`}
              disabled={pendingMessage.trim() === ""}
              onClick={sendMessage}
            />
          </Chat.InputPanel>
        </Chat>
      </div>
    </article>
  );
};

export default ChatView;
